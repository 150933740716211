$(document).ready(function () {
    let container = $("#contact-contacts").isotope({
        itemSelector: ".contact__contact",
        masonry: {
            gutter: 30
        },
        percentPosition: true
    });
    container.imagesLoaded().progress(function () {
        container.isotope('layout');
    });

    $(".contact__categories").ready(function () {
        container.isotope({filter: ".49"});
    });

    $(".contact__categories").change(function () {
        if (this.value === ".0") {
            container.isotope({filter: ""});
        } else {
            container.isotope({filter: this.value});
        }
    });
});