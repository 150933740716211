/**
 * Calculate the coordinates from an Item
 * @param elementId
 * elementId: Id of the Item with the coordinates
 * @param horizontal
 * horizontal direction of the item (left or right?)
 * @param vertical
 * vertical direction of the item (bottom or top?)
 * @returns {coords}
 * coords: x,y-coordinates horizontal and vertical direction
 * In case of scrolling: scroll-width added to x, y-coordinates
 *
 */
function getCoord(elementId, horizontal, vertical) {
    let element;


    if (document.all)
        element = document.all[elementId];
    else if (document.getElementById)
        element = document.getElementById(elementId);
    if (element, vertical, horizontal) {
        let coords = {x: 0, y: 0};
        do {
            if (horizontal === "right") {
                coords.x += element.offsetLeft + element.offsetWidth;
            } else if (horizontal === "left") {
                coords.x += element.offsetLeft;
            }
            if (vertical === "top") {
                coords.y += element.offsetTop;
            } else if (vertical === "bot") {
                coords.y += element.offsetTop + element.offsetHeight;
            }
            element = element.offset;
        }
        while (element);
        return coords;
    } else
        return null;
}


/**
 * Get called once when page get called
 * Add the event listeners to the navigation parts
 *
 */
function hoverMenu(button) {
    // loops all the navigation items
    let x = 1;
    Object.keys(button).forEach(function (i) {
        //add ids to every navigation item
        let id = "button";
        button[i].id = id.concat(x);
        //example: button1, button2, button3
        let items = document.getElementById(id.concat(x));


        //add all event listeners to the navigation items
        //when the mouse hovers the navigation item
        $(button[i]).on("mousemove", function () {

            //set submenus with ids get initialized
            let submenu = items.querySelector("div.navigation__submenu");
            if (submenu) {
                let name = "submenu";
                submenu.id = items.id.concat(name);
                //getting the menu belong to the navigation item
                let element = submenu.id;
                let menu = document.getElementById(element);

                //display the submenu, when item get hovered
                menu.style.display = "block";

                //after menu is defined the submenu is visible
                //when the mouse move this event will test, if
                //pointer is in triangle or in submenu,

                // initialize coordinates for the triangle
                //get Top coordinates from submenu and navigation item
                let A = getCoord(items.id, "left", "top");
                let B = getCoord(items.id, "right", "top");
                let C = getCoord(submenu.id, "left", "top");
                let D = getCoord(submenu.id, "right", "top");
                //helper coordinates
                let E = {x: A.x, y: C.y};
                let F = {x: B.x, y: D.y};
                //get Bot coordinates from submenu and navigation item
                let G = getCoord(submenu.id, "left", "bot");
                let H = getCoord(submenu.id, "right", "bot");
                let I = getCoord(items.id, "left", "bot");
                let J = getCoord(items.id, "right", "bot");

                //in case of scrolling: TopDifferenz will get added to the Mouse Position P
                let topDiff;

                if (document.body.scrollTop !== 0) {
                    topDiff = document.body.scrollTop;
                }
                else {
                    topDiff = document.documentElement.scrollTop;
                }

                //TODO! needTrapez true oder false

                let needTrapez = true;
                let activeLink = items.querySelector(".navigation__nav-link");

                Object.keys(links).forEach(function (i) {
                    if (links[i] !== activeLink) {
                        links[i].addEventListener("mouseover", function () {
                            needTrapez = false;
                            shutdown();
                            menu.style.display = "none";
                        });
                    }
                });


                if (needTrapez === false) {
                    shutdown();
                } else if (needTrapez === true) {
                    Object.keys(button).forEach(function (i) {
                        if (button[i].id !== items.id) {
                            $(button[i]).off("mousemove");
                        }
                    });

                    $(window).on("mousemove", function (e) {
                        //Mouse Position
                        let P = {x: e ? e.pageX : window.event.x, y: e ? e.pageY : window.event.y};
                        P.y = P.y - topDiff;
                        // console.log("e", e.originalEvent);
                        // console.log("event", event);

                        calcMousePositionInTrapez(e.originalEvent, A, B, C, D, E, F, G, H, I, J, menu, P, items);
                    });
                }
            }
        });
        x++;
    });

}


function shutdown() {
    //mousemove event shut down (can be called again by item event listener)
    $(window).off("mousemove");
    Object.keys(button).forEach(function (i) {
        $(button[i]).off("mousemove");
    });
    hoverMenu(button);
}

/**
 *
 * @param e for event
 * @param A coord
 * @param B coord
 * @param C coord
 * @param D coord
 * @param E coord
 * @param F coord
 * @param G coord
 * @param H coord
 * @param I coord
 * @param J coord
 * @param menu submenu item
 * @param P Mouse Position
 *
 */
// ********************
// ******A------B******
// *****/|**Nav*|\*****
// ****/*I------J*\****
// ***/**Trapeze***\***
// **C---E------F---D**
// **|**************|**
// **|***Submenu****|**
// **G--------------H**
// ********************

function calcMousePositionInTrapez(e, A, B, C, D, E, F, G, H, I, J, menu, P, item) {

    // console.log('calcMousePositionInTrapez');

    // do not display all the other menus
    let menus = document.getElementsByClassName("navigation__submenu");
    Object.keys(menus).forEach(function (i) {
        if (menus[i].id !== menu.id) {
            menus[i].style.display = "none";
        }
    });

    // tester for the status of the ralationship triangle an submenu with Mouse Pointer
    // tester false if Pointer not in area, true if in area
    let tester = false;


    //maths from analytical geometry
    //needed to determine the positional relationship of Point and triangles in 2D room
    //helper variables with some calculation
    let s1 = (P.x - E.x) / (C.x - E.x);
    let r1 = (P.y - E.y) / (A.y - E.y);
    let r3 = (P.x - F.x) / (D.x - F.x);
    let s3 = (P.y - F.y) / (B.y - F.y);
    let sr1 = s1 + r1;
    let sr2 = s3 + r3;

    //all the cases when tester have to be true
    if (P.x > A.x && P.x < B.x && P.y < I.y && P.y > A.y) {
        // ABIJ Quadrat
        menu.style.display = "block";
        tester = true;
    } else if (A.x < P.x && P.x < B.x && P.y < F.y && P.y > A.y) {
        //IJEF Quadrat
        menu.style.display = "block";
        tester = true;
    } else if (0 < s1 && s1 < 1 && 0 < r1 && r1 < 1 && 0 < sr1 && 1 > sr1) {
        // triangle ACE
        menu.style.display = "block";
        tester = true;
    } else if (0 < s3 && s3 < 1 && 0 < r3 && r3 < 1 && 0 < sr2 && 1 > sr2) {
        // triangle BFD
        menu.style.display = "block";
        tester = true;
    } else if (P.x < H.x && P.x > G.x && P.y > C.y - 5 && P.y < H.y) {
        // CDGH Quadrat
        menu.style.display = "block";
        tester = true;
    }

    if (tester === false) {
        menu.style.display = "none";
    }


    //at that time when the Pointer leaves the area the tester is false
    if (tester === false) {
        //mousemove event shut down (can be called again by item event listener)
        $(window).off("mousemove");
        Object.keys(button).forEach(function (i) {
            $(button[i]).off("mousemove");
        });
        hoverMenu(button);
    }
}


let button = document.getElementsByClassName("navigation__nav-item");
let links = document.getElementsByClassName("navigation__nav-link");
hoverMenu(button);
