const bindEvents = () => {
    let eventDays = document.querySelectorAll(".calendarphp__eventDay-active, .calendarphp__eventDay-eventNotInMonth, .calendarphp__eventDay-eventToday");
    let events = document.querySelectorAll(".calendarphp__event");
    let allEvents = document.getElementById("events");
    let closeButton = document.querySelectorAll("i.closeButton");

    let groupedEvents = [];

    if (closeButton) {
        Object.keys(closeButton).forEach(function (i) {
            closeButton[i].addEventListener("click", function () {
                Object.keys(events).forEach(function (i) {
                    events[i].classList.add("calendarphp__event-inactive");
                });
                allEvents.style.display = "block";
            });
        });
    }

    if (events) {
        // group events by day
        events.forEach((event) => {
            let key = new Date(event.dataset.date.replace(/-/g, "/")).getDate();
            if (typeof groupedEvents[key] === 'undefined') {
                groupedEvents[key] = [];
            }
            groupedEvents[key].push(event);
        })
    }

    if (eventDays) {
        eventDays.forEach(function (day) {
            day.addEventListener("click", function () {
                allEvents.style.display = "none";

                // hide all events
                groupedEvents.forEach((group) => {
                    group.forEach((eventInGroup) => {
                        eventInGroup.classList.add("calendarphp__event-inactive");
                    })
                })

                // show clicked events
                groupedEvents[day.dataset.day].forEach((event) => {
                    event.classList.remove("calendarphp__event-inactive");
                })
            });
        });
    }
};

let calendar = document.querySelector('#calendar');
let events = document.querySelector('#events-in-calendar');

if (calendar) {
    const Calendar = {
        data: {
            month: parseInt(calendar.dataset.month),
            calendar: calendar,
        },
        async getCalendar() {
            let response = await fetch(`/events?language=${document.location.pathname.split('/')[1]}`
                + `&month=${this.data.month}`);
            return response.text();
        },
        async updateCalendar() {
            this.data.calendar.innerHTML = await this.getCalendar();
            await CalendarEvents.updateEvents();
            this.setupBindings()
        },
        setupBindings() {
            let btnLeft = document.querySelector('.calendarphp__headline').firstElementChild;
            let btnRight = document.querySelector('.calendarphp__headline').lastElementChild;

            bindEvents();

            btnLeft.addEventListener("click", () => {
                this.data.month--;
                CalendarEvents.data.month--;
                this.updateCalendar().then(() => console.log('calendar updated'));
            });

            btnRight.addEventListener("click", () => {
                this.data.month++;
                CalendarEvents.data.month++;
                this.updateCalendar().then(() => console.log('calendar updated'));
            });
        },
    };

    const CalendarEvents = {
        data: {
            upcomingEvents: document.querySelector('#events'),
            month: events.dataset.month,
            events: events,
        },
        async getEvents() {
            let response = await fetch(`/event-details?language=${document.location.pathname.split('/')[1]}`
                + `&month=${this.data.month}`);
            return response.text();
        },
        async updateEvents() {
            this.data.events.innerHTML = await this.getEvents();
            this.displayUpcoming();
        },
        displayUpcoming() {
            this.data.upcomingEvents.style.display = 'block';
        }
    };

    document.addEventListener("DOMContentLoaded", function () {
        Calendar.setupBindings();
    });

    function groupBy(array, key) {
        return array.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
}