$(document).ready(function () {
    if (document.body.clientWidth <= 978) {
        let navItems = document.querySelectorAll(".footer__nav-link");
        let childItmes = document.querySelectorAll(".footer__subnav-wrapper");

        let x = 1;
        navItems.forEach(function(i) {
            i.id = "1" + x;
            x++;
        });

        let y = 1;
        childItmes.forEach(function(i) {
            if (i.innerHTML.trim() === "") {
                y++;
            } else {
                let id = "menu";
                i.id = id.concat("1" + y);
                y++;
            }
        });

        navItems.forEach(function(el) {
            if (document.getElementById("menu" + el.id) !== null) {
                el.addEventListener("click", function (event) {
                    document.getElementById("menu" + el.id).classList.toggle("footer__subnav-wrapper--active");
                    event.preventDefault();
                });
            }
        });

       /* $(".footer__nav-link").click(function (event) {

            let children = $(this).children(".footer__subnav-wrapper");

            console.log(children.length);

            if (children.length !== 0) {

                children.toggleClass("footer__subnav-wrapper--active");
                event.preventDefault();
            }
        }).each(function (index) {
            let children = $(this).children(".footer__subnav-wrapper");

            if(children.length !== 0) {
                $(this).children(".footer__nav-link").append('<i class="fa fa-chevron-down" aria-hidden="true"></i>');
            }
        });*/
    }
});