//get markets and dots on svg
let markets = document.getElementsByClassName("spot-list__spot");
let dots = document.getElementsByClassName("spot-list__dot");

//dots with onclick event
Object.keys(dots).forEach(function (i) {
    dots[i].addEventListener("mouseover", function () {
        //only one active market and dot at once
        Object.keys(dots).forEach(function (i) {
            dots[i].classList.remove('spot-list__dot--active');
        });
        Object.keys(markets).forEach(function (i) {
            markets[i].classList.remove('spot-list__spot--active');
        });
        //active the market and the dot with the id
        dots[i].classList.add('spot-list__dot--active');
        markets[i].classList.add('spot-list__spot--active');
    });
});

//dots with onclick event
Object.keys(markets).forEach(function (i) {
    markets[i].addEventListener("click", function () {
        //only one active market and dot at once
        Object.keys(dots).forEach(function (i) {
            dots[i].classList.remove('spot-list__dot--active');
        });
        Object.keys(markets).forEach(function (i) {
            markets[i].classList.remove('spot-list__spot--active');
        });
        //active the market and the dot with the id
        dots[i].classList.add('spot-list__dot--active');
        markets[i].classList.add('spot-list__spot--active');
    });
});