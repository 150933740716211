$(document).ready(function () {

    let container = $(".gas-station__stations").isotope({
        itemSelector: ".gas-station__station",
        masonry: {
            gutter: 60
        },
        percentPosition: true
    });

    container.imagesLoaded().progress(function () {
        container.isotope('layout');
    });

    $(".gas-station__categories").change(function () {
        if (this.value === ".0") {
            container.isotope({filter: ""});
        } else {
            container.isotope({filter: this.value});
        }

        $("#gas-station-search-count").text(container.isotope('getFilteredItemElements').length);
    });

});