/* When the user clicks on the button,
toggle between hiding and showing the dropdown content */
let smallDropLanguage = document.getElementsByClassName("smallDropDown");
if (smallDropLanguage) {
    Object.keys(smallDropLanguage).forEach(function (i) {
        smallDropLanguage[i].addEventListener("click", function () {
            document.getElementById("mySmallDropdown").classList.toggle("show");
        });
    });
}

let dropLanguage = document.getElementsByClassName("dropdown");
if (dropLanguage) {
    Object.keys(dropLanguage).forEach(function (i) {
        dropLanguage[i].addEventListener("click", function () {
            document.getElementById("myDropdown").classList.toggle("show");
        });
    });
}


// Close the dropdown if the user clicks outside of it
window.onclick = function (event) {
    if (!event.target.matches('.dropbtn') && !event.target.matches('.dropdown-arrow')) {

        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
    }
}