let nav = {
    navigation: document.getElementById("navigation"),
    logoColor: document.getElementById("logo-color"),
    logoWhite: document.getElementById("logo-white"),
    burger: document.querySelector("#burger-btn"),
    button: document.querySelector("#menu-button"),
    menu: document.querySelector("#menu"),
    bg: document.querySelector("#menu-bg"),
    header: document.querySelector(".header"),
    vw: document.body.clientWidth,
    showSearch: document.querySelector("#show-search"),
    searchForm: document.querySelector("#search"),
};

function scrollNav() {
    "use strict";

    window.addEventListener("scroll", function () {

        if (window.scrollY > 0 || window.pageYOffset) { //apple gibt auch negative scroll werte durch -_-'
            nav.navigation.classList.add("navigation--scroll");
            nav.logoColor.classList.add("navigation__img--active");
            nav.logoWhite.classList.remove("navigation__img--active");

            if (nav.vw <= 990) {
                nav.header.classList.add("header--shadow");
            }


        } else if (!nav.burger.classList.contains("open") || nav.vw > 990) {
            nav.navigation.classList.remove("navigation--scroll");
            nav.logoColor.classList.remove("navigation__img--active");
            nav.logoWhite.classList.add("navigation__img--active");

            if (nav.vw <= 990) {
                nav.header.classList.remove("header--shadow");
            }
        }
    });

}

function mobileMenu() {
    "use strict";

    let menuNav = document.querySelector(".menu__nav");

    if (nav.vw <= 990) {

        let mobileNavItems = document.querySelectorAll(".menu__nav-link");
        let elChild = document.querySelectorAll(".menu__submenu");
        let x = 1;
        mobileNavItems.forEach(function (i) {
            i.id = x;
            x++;
        });
        let y = 1;
        elChild.forEach(function (i) {
            if (i.innerHTML.trim() === "") {
                y++;
            } else {
                let id = "menu";
                i.id = id.concat(y);
                y++;
            }

        });

        mobileNavItems.forEach(function (el) {
            if (document.getElementById("menu" + el.id) !== null) {
                el.addEventListener("click", function (event) {
                    mobileNavItems.forEach(function (elem) {
                        if (el.id !== elem.id) {
                            document.getElementById("menu" + elem.id).classList.remove("menu__submenu--active");
                        }
                    });
                    document.getElementById("menu" + el.id).classList.toggle("menu__submenu--active");
                    event.preventDefault();
                });
            }

        });

        // gesammte viewport höhe
        // - 15px oben und unten (30)
        // - 40px des menu__bottom 1.2rem lineheight und margin 10
        // - der navigationshöhe (variiert je nach größe des bildes
        menuNav.style.maxHeight = "calc(" +
            " 85vh - " +
            "30px - 40px - " +
            nav.navigation.offsetHeight + "px" +
            ")";
    } else {
        menuNav.style.maxHeight = "none";
    }
}

function menu() {
    "use strict";

    if (nav.button) {
        nav.button.addEventListener("click", function (event) {

            event.preventDefault();

            if (nav.vw <= 990 && window.scrollY <= 0) {
                nav.navigation.classList.toggle("navigation--scroll");
                nav.header.classList.toggle("header--shadow");
                nav.logoColor.classList.toggle("navigation__img--active");
                nav.logoWhite.classList.toggle("navigation__img--active");
            }


            if (nav.burger.classList.contains("open")) {
                nav.burger.classList.remove("open");
                nav.bg.style.opacity = "0";
                nav.menu.style.opacity = "0";

                // setTimeout(function () {
                    nav.menu.style.display = "none";
                    nav.bg.style.display = "none";
                // }, 800);

            } else {
                nav.menu.style.display = "flex";
                nav.burger.classList.add("open");

                if (nav.vw > 990) {
                    nav.bg.style.display = "block";
                }

                // setTimeout(function () {
                    nav.bg.style.opacity = "1";
                    nav.menu.style.opacity = "1";
                // }, 100);

            }
            console.log(event);
        });
    }


    if (nav.bg) {
        nav.bg.addEventListener("click", function (event) {

            setTimeout(function () { //transition finished event?
                nav.bg.style.display = "none";
                nav.menu.style.display = "none";
                nav.burger.classList.remove("open");
                if (nav.vw >= 990 && window.scrollY <= 0) {
                    nav.navigation.classList.remove("navigation__img--scroll");
                    nav.logoColor.classList.remove("navigation__img--active");
                    nav.logoWhite.classList.add("navigation__img--active");
                }
            }, 250);

            // console.log(event);
        });
    }

}

function bindSearch() {
    "use strict";
    nav.showSearch.addEventListener("click", function () {
        nav.searchForm.classList.toggle("navigation__search-form--active");
    });
}


scrollNav();
menu();
mobileMenu();
bindSearch();


window.addEventListener("resize", function () {
    "use strict";

    nav.vw = document.body.clientWidth;
    mobileMenu();
});

//separate scroll for Menu
var toggle = false;
$("#menu-button").on("click",function () {
    if (toggle === false) {
        $("body").css("overflow", "hidden");
        toggle = true;
    } else if (toggle === true) {
        $("body").css("overflow", "scroll");
        toggle = false;
    }
});