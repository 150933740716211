var testElementES = document.querySelectorAll("[data-countup]");

if (testElementES) {
    var easingFn = function (t, b, c, d) {
        var ts = (t /= d) * t;
        var tc = ts * t;
        return b + c * (tc * ts + -5 * ts * ts + 10 * tc + -10 * ts + 5 * t);
    };

    var options = {
        useEasing: true,
        easingFn: easingFn,
        useGrouping: true,
        separator: '.',
        decimal: ',',
    };
    var demo = [];

    Object.keys(testElementES).forEach(function (i) {

        demo[i] = new CountUp(testElementES[i], 0, testElementES[i].dataset.countup, 0, 10, options);

        if (!demo[i].error) {
            demo[i].start();
        } else {
            console.error(demo[i].error);
        }

    });


}


